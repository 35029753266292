import { Merchant } from "./merchant";
import { MerchantTransaction } from "./merchantTransaction";

export enum RecordSalePaymentType {
  Bank = "BANK", //ngân hàng
  Point = "POINT", //điểm
}

export const RecordSalePaymentTypeTrans = {
  [RecordSalePaymentType.Point]: {
    label: "Điểm",
    value: RecordSalePaymentType.Point,
  },
  [RecordSalePaymentType.Bank]: {
    label: "Ngân hàng",
    value: RecordSalePaymentType.Bank,
  },
};

export enum RecordSalePaymentStatus {
  Pending = "PENDING", //chưa thanh toán
  Complete = "COMPLETE", //đã thanh toán
}

export const RecordSalePaymentStatusTrans = {
  [RecordSalePaymentStatus.Pending]: {
    label: "Chưa thanh toán",
    value: RecordSalePaymentStatus.Pending,
    color: "red",
  },
  [RecordSalePaymentStatus.Complete]: {
    label: "Đã thanh toán",
    value: RecordSalePaymentStatus.Complete,
    color: "green",
  },
};

export interface RecordSaleDetails {
  commission: number,
  content: string
}
export interface RecordSale {
  id: number;
  createdAt: number;
  updatedAt: number;
  isDeleted: boolean;
  deletedAt: number;
  deletedBy: string;
  code: string;
  paymentType: RecordSalePaymentType;
  paymentStatus: RecordSalePaymentStatus;
  transactionCode: string;
  bankAccountNumber: string; //stk ngân hàng
  bankAccountName: string; //tên TK ngân hàng
  bankName: string; //tên ngân hàng
  month: string; //format: YYYY-MM
  details: string //* RecordSaleDetails Json
  commissionPercent: number;
  moneyAppointment: number; //tiền đặt lịch
  totalAppointment: number; //tổng lịch đặt thành công
  totalInvalidConfirmAppointment: number; //tổng lịch đặt xác nhận không hợp lệ
  totalBadReview: number;
  totalGoodReview: number;
  moneyServicePack: number; //tiền mua gói dịch vụ
  moneyVoucher: number; //tiền mua voucher
  moneyRenew: number; //tiền theo gói thuê(price / 12 tháng)
  moneyCommission: number;
  amount: number; //tổng chiết khấu phải trả
  merchant: Merchant;
  merchantTransaction: MerchantTransaction;
  transNo: number;
  renewPackage: RenewPackage;
}
export interface RenewPackage {
  id: number;
  createdAt: number;
  updatedAt: number;
  isDeleted: boolean;
  deletedAt: number;
  deletedBy: string;
  name: string;
  // type: RenewPackageType
  desc: string;
  price: number;
}
