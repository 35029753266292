import { InfoCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Input,
  Space,
  Spin,
  Table,
  Image,
  Tag,
  message,
  DatePicker,
  Checkbox,
  Select,
  Popover,
  Tooltip,
} from "antd";
import { Pagination } from "components/Pagination";
import { useEffect, useRef, useState } from "react";
import { formatVND, getTitle } from "utils";
import { withdrawApi } from "api/withdraw.api";
import useRecordSale, { RecordSaleQuery } from "hooks/useRecordSale";
import {
  RecordSale,
  RecordSaleDetails,
  RecordSalePaymentStatus,
  RecordSalePaymentStatusTrans,
  RecordSalePaymentType,
  RecordSalePaymentTypeTrans,
} from "types/recordSale";
import dayjs, { Dayjs } from "dayjs";
import { CheckboxProps, Divider } from "antd/lib";
import {
  IPaymentConfirmModal,
  PaymentConfirmModal,
} from "./components/PaymentConfirmModal";
import { formattedFullDate } from "utils/formatDate";
import RecordSaleExportExcel from "views/Dashboard/components/RecordSaleExportExcel";
import { recordSaleApi } from "api/recordSale.api";
import moment from "moment";
import useRenewPackage from "hooks/useRenewPackage";
import { MerchantModal } from "views/Merchant/components/MerchantModal";
import { Merchant } from "types/merchant";
import { ModalStatus } from "types/modal";
import { DashboardQuery } from "hooks/useDashboard";

const { Column } = Table;

interface IProps {
  title?: string;
  merchantId?: number;
  isShowAction?: boolean;
  querySummarySale?: RecordSaleQuery;
  isBoxStyle?: boolean;
  description?: string;
}
const { RangePicker } = DatePicker;
export const RecordSalePage = ({
  title = "",
  merchantId,
  isShowAction = true,
  isBoxStyle = true,
  querySummarySale,
  description,
}: IProps) => {
  const [note, setNote] = useState<string>();
  const [loadingConfirmWithdraw, setLoadingConfirmWithdraw] = useState(false);
  const [loadingCancelWithdraw, setLoadingCancelWithdraw] = useState(false);
  const [isEnoughBalance, setIsEnoughBalance] = useState(false);
  const paymentConfirmModalRef = useRef<IPaymentConfirmModal>();
  const [loadingTrigger, setLoadingTrigger] = useState(false);
  const [loadingGetLastUpdated, setLoadingGetLastUpdated] = useState(false);
  const [isModalMerchantVisible, setIsModalMerchantVisible] = useState(false);
  const [modalMerchantStatus, setModalMerchantStatus] =
    useState<ModalStatus>("create");
  const [lastUpdateUnix, setLastUpdateUnix] = useState<number>(0); //* Thời gian cập nhật tính hoa hồng lần cuối
  const [selectedMerchant, setSelectedMerchant] = useState<Merchant>();
  const currentMonthString = dayjs().format("YYYY-MM");
  // const totalSummaryRef = useRef<any>();
  const currDay = dayjs();
  const initialQuery = {
    fromAt: currDay.startOf("day").startOf("month"),
    toAt: currDay.endOf("day").endOf("month"),
  };
  const { data: renewPackages, fetchData: fetchRenewPackage } = useRenewPackage(
    {
      initialQuery: { page: 1, limit: 50 },
    }
  );

  const {
    fetchData: fetchRecordSale,
    query: queryRecordSale,
    data: recordSales,
    loading: loadingRecordSales,
    total: totalRecordSales,
  } = useRecordSale({
    initialQuery: querySummarySale || {
      page: 1,
      limit: 50,
      fromAt: initialQuery.fromAt.unix(),
      toAt: initialQuery.toAt.unix(),
    },
  });
  useEffect(() => {
    document.title = getTitle(title);
    handleFetchLastUpdate();
  }, []);

  useEffect(() => {
    if (querySummarySale) {
      Object.assign(queryRecordSale, {
        ...querySummarySale,
      });
      fetchRecordSale();
    }
    queryRecordSale.merchantId = merchantId;
    fetchRecordSale();
  }, [merchantId, querySummarySale]);

  const handleConfirmWithdraw = async (withdrawId: number) => {
    try {
      setLoadingConfirmWithdraw(true);

      const res = await withdrawApi.complete(withdrawId, {});
      message.success("Xác nhận lệnh rút thành công!");
      fetchRecordSale();
    } catch (error) {
    } finally {
      setLoadingConfirmWithdraw(false);
    }
  };

  const handleCancelWithdraw = async (withdrawId: number, note: string) => {
    try {
      setLoadingCancelWithdraw(true);
      const res = await withdrawApi.cancel(withdrawId, {
        note,
      });
      message.success("Từ chối lệnh rút thành công!");
      fetchRecordSale();
    } catch (error) {
    } finally {
      setLoadingCancelWithdraw(false);
    }
  };

  // const getDateQuery = (date?: Dayjs) => {
  //   return {
  //     month: dayjs(date).format("YYYY-MM"),
  //   };
  // };

  const onChange: CheckboxProps["onChange"] = (e) => {
    const value = e.target.checked;
    if (value) {
      queryRecordSale.isEnoughBalance = value;
    } else {
      queryRecordSale.isEnoughBalance = undefined;
    }

    fetchRecordSale();
    setIsEnoughBalance(value);
  };
  const handleFetchLastUpdate = async () => {
    setLoadingGetLastUpdated(true);
    try {
      const { data } = await recordSaleApi.lastUpdate({
        month:
        dayjs.unix(queryRecordSale.toAt).startOf('month').format('YYYY-MM') ||
        dayjs.unix(queryRecordSale.fromAt).startOf('month').format('YYYY-MM')
      });
      setLastUpdateUnix(data.lastUpdated);
    } finally {
      setLoadingGetLastUpdated(false);
    }
  };
  const handleTrigger = async () => {
    setLoadingTrigger(true);
    try {
      await recordSaleApi.trigger({
        month:
        dayjs.unix(queryRecordSale.toAt).startOf('month').format('YYYY-MM') ||
        dayjs.unix(queryRecordSale.fromAt).startOf('month').format('YYYY-MM')
      });
      handleFetchLastUpdate();
    } finally {
      setLoadingTrigger(false);
    }
  };

  const disableFutureMonths = (current: Dayjs) => {
    // Disable months after the current month
    return current && current > dayjs().endOf("month");
  };
  const onChangeRangePicker = (date: any, dateFormat: string[]) => {
    if (date) {
      queryRecordSale.toAt = dayjs(date[1]).endOf("day").unix();
      queryRecordSale.fromAt = dayjs(date[0]).startOf("day").unix();
    } else {
      queryRecordSale.toAt = undefined;
      queryRecordSale.fromAt = undefined;
    }
    fetchRecordSale();
    handleFetchLastUpdate();
  };
  return (
    <section className={`${isBoxStyle ? "box" : ""}`}>
      {title && (
        <div className="flex flex-col gap-1 mb-5">
          <h1 className="text-xl mb-0 ">{title}</h1>
          <span className="font-medium">{description}</span>
        </div>
      )}
      <div className="filter-container">
        <Space wrap>
          <div className="filter-item">
            <label htmlFor="">Tìm kiếm</label>
            <br />
            <Input
              size="middle"
              onChange={(ev) => {
                queryRecordSale.search = ev.target.value;
                fetchRecordSale();
              }}
              placeholder="Tìm kiếm theo mã"
            />
          </div>

          <div className="filter-item">
            <label htmlFor="">Khoảng thời gian</label>
            <br />
            {/* <div>
              <DatePicker.MonthPicker
                defaultValue={dayjs(currentMonthString)}
                onChange={(data) => {
                  if (data) {
                    queryRecordSale.month = dayjs(data).format("YYYY-MM");
                    queryRecordSale.fromAt = data.unix();
                  } else {
                    queryRecordSale.month = undefined;
                    queryRecordSale.fromAt = undefined;
                  }
                  fetchRecordSale();
                  handleFetchLastUpdate();
                }}
                // disabledDate={disableFutureMonths}
                format={"MM-YYYY"}
                style={{ height: 32 }}
                allowClear={false}
                // value={
                //   queryRecordSale.fromAt
                //     ? dayjs.unix(queryRecordSale.fromAt)
                //     : dayjs(queryRecordSale.month)
                // }
                value={
                  queryRecordSale.month
                    ? dayjs(queryRecordSale.month)
                    : dayjs.unix(queryRecordSale.fromAt)
                }
              />
            </div> */}
            <RangePicker
              value={[
                dayjs.unix(queryRecordSale?.fromAt),
                dayjs.unix(queryRecordSale?.toAt),
              ]}
              onChange={onChangeRangePicker}
              picker="month"
              className="custom-filter w-[300px]"
              size="large"
              allowClear={false}
            />
          </div>
          <div className="filter-item">
            <label htmlFor="">Trạng thái thanh toán</label>
            <br />
            <div>
              <Select
                placeholder="Chọn trạng thái"
                allowClear
                className="!h-auto"
                onChange={(val) => {
                  queryRecordSale.paymentStatus = val;
                  fetchRecordSale();
                }}
                options={Object.values(RecordSalePaymentStatusTrans).map(
                  (item) => {
                    return {
                      label: item.label,
                      value: item.value,
                    };
                  }
                )}
                style={{ width: 200 }}
              ></Select>
            </div>
          </div>
          <div className="filter-item">
            <label htmlFor="" className="!pb-1">
              Phương thức thanh toán
            </label>
            <br />
            <div>
              <Select
                placeholder="Chọn phương thức"
                allowClear
                className="!h-auto"
                onChange={(val) => {
                  queryRecordSale.paymentType = val;
                  fetchRecordSale();
                }}
                options={Object.values(RecordSalePaymentTypeTrans).map(
                  (item) => {
                    return {
                      label: item.label,
                      value: item.value,
                    };
                  }
                )}
                style={{ width: 200 }}
              ></Select>
            </div>
          </div>
          <div className="filter-item btn ">
            <RecordSaleExportExcel
              summaries={recordSales}
              queryRecordSale={queryRecordSale}
            />
          </div>
          {/* <div className="filter-item mt-6">
            <Button
              type="primary"
              loading={false}
              // icon={<ExportOutlined />}
              size="middle"
              onClick={handleFetchLastUpdate}
              // disabled={!summaries.length}
            >
              Last UPdate
            </Button>
          </div> */}
          <div className="filter-item btn  ">
            <Button
              type="primary"
              loading={loadingTrigger}
              // icon={<ExportOutlined />}
              size="middle"
              onClick={handleTrigger}
              // disabled={!summaries.length}
            >
              Tính hoa hồng
            </Button>
          </div>
          <Spin spinning={loadingGetLastUpdated}>
            <div className="filter-item btn mt-4">
              <span className="font-medium text-primary">
                Lần cuối cập nhật hoa hồng:{" "}
                {lastUpdateUnix
                  ? dayjs.unix(lastUpdateUnix).format("HH:mm, DD/MM/YYYY")
                  : "Không có thông tin"}
              </span>
            </div>
          </Spin>
        </Space>
      </div>

      <div className="flex gap-2 items-center mb-2">
        {isShowAction && (
          <div className=" flex items-center">
            <Checkbox onChange={onChange} checked={isEnoughBalance}>
              <span className="font-medium text-primary">
                Chỉ hiển thị cửa hàng đủ điểm thanh toán
              </span>
            </Checkbox>
          </div>
        )}
        <Divider type="vertical" className="!mx-0" />
      </div>
      <Table
        loading={loadingRecordSales}
        pagination={false}
        rowKey="id"
        dataSource={recordSales}
        // className="max-h-[300px]"

        // size={"small"}
        summary={(pageData) => {
          let totalAppointmentNumber = 0;
          let totalBadReviewNumber = 0;
          let totalGoodReviewNumber = 0;
          let totalInvalidConfirmAppointmentNumber = 0;
          let totalMoneyVoucherNumber = 0;
          let totalMoneyServicePackNumber = 0;
          let totalMoneyRenewNumber = 0;
          let totalMoneyCommissionNumber = 0;
          let totalMoneyAppointmentNumber = 0;
          let totalCommissionPercentNumber = 0;
          let totalAmount = 0;
          // let totalSummaryObject = {
          //   totalAppointmentNumber: 0,
          //   totalInvalidConfirmAppointmentNumber: 0,
          //   totalBadReviewNumber: 0,
          //   totalGoodReviewNumber: 0,
          //   totalMoneyAppointmentNumber: 0,
          //   totalMoneyVoucherNumber: 0,
          //   totalMoneyServicePackNumber: 0,
          //   totalMoneyCommissionNumber: 0,
          //   totalMoneyRenewNumber: 0,
          //   totalCommissionPercentNumber: 0,
          //   totalAmount: 0,
          // };
          pageData.forEach(
            ({
              amount,
              commissionPercent,
              moneyAppointment,
              totalBadReview,
              totalInvalidConfirmAppointment,
              totalGoodReview,
              totalAppointment,
              moneyVoucher,
              moneyServicePack,
              moneyRenew,
              moneyCommission,
            }) => {
              // totalSummaryObject = {
              //   totalAppointmentNumber:
              //     totalSummaryObject.totalAppointmentNumber + totalAppointment,
              //   totalBadReviewNumber:
              //     totalSummaryObject.totalBadReviewNumber + totalBadReview,
              //   totalGoodReviewNumber:
              //     totalSummaryObject.totalGoodReviewNumber + totalGoodReview,
              //   totalInvalidConfirmAppointmentNumber:
              //     totalSummaryObject.totalInvalidConfirmAppointmentNumber +
              //     totalInvalidConfirmAppointment,
              //   totalMoneyVoucherNumber:
              //     totalSummaryObject.totalMoneyVoucherNumber + moneyVoucher,
              //   totalMoneyServicePackNumber:
              //     totalSummaryObject.totalMoneyServicePackNumber +
              //     moneyServicePack,
              //   totalMoneyRenewNumber:
              //     totalSummaryObject.totalMoneyRenewNumber + moneyRenew,
              //   totalMoneyCommissionNumber:
              //     totalSummaryObject.totalMoneyCommissionNumber +
              //     moneyCommission,
              //   totalMoneyAppointmentNumber:
              //     totalSummaryObject.totalMoneyAppointmentNumber +
              //     moneyAppointment,
              //   totalCommissionPercentNumber:
              //     totalSummaryObject.totalCommissionPercentNumber +
              //     commissionPercent,
              //   totalAmount: totalSummaryObject.totalAmount + amount,
              // };
              totalAppointmentNumber += totalAppointment;
              totalBadReviewNumber += totalBadReview;
              totalGoodReviewNumber += totalGoodReview;
              totalInvalidConfirmAppointmentNumber +=
                totalInvalidConfirmAppointment;
              totalMoneyVoucherNumber += moneyVoucher;
              totalMoneyServicePackNumber += moneyServicePack;
              totalMoneyRenewNumber += moneyRenew;
              totalMoneyCommissionNumber += moneyCommission;
              totalMoneyAppointmentNumber += moneyAppointment;
              totalCommissionPercentNumber += commissionPercent;
              totalAmount += amount;
            }
          );
          // console.log(totalSummaryObject);
          return (
            <Table.Summary fixed>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>
                  <span className="text-base font-semibold">Tổng cộng</span>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1}>
                  <span className="text-primary block text-end font-bold cursor-pointer text-base">
                    {/* {formatVND(totalAppointmentSale)} */}
                  </span>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2}>
                  <span className="text-primary block text-end font-bold cursor-pointer text-base">
                    {/* {formatVND(totalServicePackSale)} */}
                  </span>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={3}>
                  <span className="text-primary block text-end font-bold cursor-pointer text-base">
                    {/* {formatVND(totalVoucherSale)} */}
                  </span>
                </Table.Summary.Cell>
                {/* <Table.Summary.Cell index={4}>
                  <span className="text-primary block text-end font-bold cursor-pointer text-base">
                    {formatVND(totalAll)}
                  </span>
                </Table.Summary.Cell> */}
                {/* {Object.entries(totalSummaryObject)
                  .filter(
                    ([key, value]) => key != "totalCommissionPercentNumber"
                  )
                  .map(([key, value], index) => {
                    return (
                      <Table.Summary.Cell index={index}>
                        <span className="text-primary font-medium block text-end  ">
                          {formatVND(value)}
                        </span>
                      </Table.Summary.Cell>
                    );
                  })} */}
                <Table.Summary.Cell index={4}>
                  <span className="text-primary font-medium block text-end  ">
                    {/* {formatVND(totalAppointmentNumber)} */}
                  </span>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={5}>
                  <span className="text-primary font-medium block text-end  ">
                    {formatVND(totalAppointmentNumber)}
                  </span>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={6}>
                  <span className="text-primary font-medium block text-end  ">
                    {formatVND(totalInvalidConfirmAppointmentNumber)}
                  </span>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={7}>
                  <span className="text-primary font-medium block text-end  ">
                    {formatVND(totalGoodReviewNumber)}
                  </span>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={8}>
                  <span className="text-primary font-medium block text-end  ">
                    {formatVND(totalBadReviewNumber)}
                  </span>
                </Table.Summary.Cell>
                {isShowAction && (
                  <>
                    <Table.Summary.Cell index={9}>
                      <span className="text-primary font-medium block text-end  ">
                        {formatVND(totalMoneyAppointmentNumber)}
                      </span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={10}>
                      <span className="text-primary font-medium block text-end  ">
                        {formatVND(totalMoneyVoucherNumber)}
                      </span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={11}>
                      <span className="text-primary font-medium block text-end  ">
                        {formatVND(totalMoneyServicePackNumber)}
                      </span>
                    </Table.Summary.Cell>
                  </>
                )}
                {/* <Table.Summary.Cell index={12}>
                  <span className="text-primary font-medium block text-end  "></span>
                </Table.Summary.Cell> */}
                <Table.Summary.Cell index={12}>
                  <span className="text-primary font-medium block text-end  ">
                    {formatVND(totalMoneyCommissionNumber)}
                    {/* (
                    {formatVND(totalCommissionPercentNumber)}%) */}
                  </span>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={13}>
                  <span className="text-primary font-medium block text-end  ">
                    {formatVND(totalMoneyRenewNumber)}
                  </span>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={14}>
                  <span className="text-primary font-bold block text-end ">
                    {formatVND(totalAmount)}
                  </span>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          );
        }}
        scroll={{
          x: "max-content",
          y: 550,
        }}
      >
        <Column
          align="center"
          width={150}
          title="Tháng"
          render={(text, record: RecordSale) => (
            <div className="flex flex-col gap-1 items-center">
              <span>{dayjs(record.month).format("MM-YYYY")}</span>
            </div>
          )}
        />
        <Column
          width={150}
          title="Mã thanh toán"
          render={(text, record: RecordSale) => (
            <span className="text-secondary font-medium">{record.code}</span>
          )}
        />
        <Column
          width={250}
          title="Cửa hàng"
          render={(text, record: RecordSale) => (
            <div className="flex items-center gap-3">
              <Image
                src={record.merchant.avatar}
                preview={false}
                className="rounded-full !w-[40px] !h-[40px] object-cover shadow-md"
              />
              <div className="flex flex-col flex-1">
                <span
                  onClick={
                    () => {
                      setIsModalMerchantVisible(true);
                      setModalMerchantStatus("update");
                      setSelectedMerchant(record.merchant);
                    }
                    // : () => message.info("Vui lòng duyệt tài khoản trước")
                  }
                  className="text-secondary font-medium cursor-pointer hover:underline"
                >
                  {record?.merchant?.name}
                </span>
                <Tooltip title={record.merchant.address}>
                  <span className="text-[#8f8b8b] line-clamp-2">
                    {record.merchant.address}
                  </span>
                </Tooltip>
              </div>
            </div>
          )}
        />
        <Column
          width={200}
          title="Liên hệ"
          render={(text, record: RecordSale) => (
            <div className="flex items-center gap-2">
              <div className="flex flex-col">
                <span className="text-[#8f8b8b]">{record.merchant.email}</span>
                <span className="">{record.merchant.phone}</span>
              </div>
            </div>
          )}
        />
        <Column
          width={110}
          title="Điểm"
          align="right"
          render={(text, record: RecordSale) => (
            <span className="text-secondary font-medium">
              {formatVND(record.merchant.balance)}
            </span>
          )}
        />
        <Column
          width={120}
          title="Tổng lịch đặt"
          align="right"
          dataIndex={"totalAppointment"}
          className="text-primary font-medium "
        />
        <Column
          width={220}
          title="Tổng lịch đặt xác nhận trễ"
          align="right"
          dataIndex={"totalInvalidConfirmAppointment"}
          className="text-primary font-medium "
        />
        <Column
          width={150}
          title="Tổng đánh giá tốt"
          align="right"
          dataIndex={"totalGoodReview"}
          className="text-primary font-medium "
        />
        <Column
          width={170}
          title="Tổng đánh giá xấu"
          align="right"
          dataIndex={"totalBadReview"}
          className="text-primary font-medium "
        />
        {isShowAction && (
          <>
            <Column
              align="right"
              width={200}
              title="Doanh thu lịch đặt"
              render={(text, record: RecordSale) => (
                <div className="flex flex-col gap-1">
                  <span className="text-primary font-medium ">
                    {formatVND(record.moneyAppointment)}
                  </span>
                </div>
              )}
            ></Column>
            <Column
              align="right"
              width={200}
              title="Doanh thu voucher"
              render={(text, record: RecordSale) => (
                <div className="flex flex-col gap-1">
                  <span className="text-primary font-medium ">
                    {formatVND(record.moneyVoucher)}
                  </span>
                </div>
              )}
            ></Column>
            <Column
              align="right"
              width={200}
              title="Doanh thu gói dịch vụ"
              render={(text, record: RecordSale) => (
                <div className="flex flex-col gap-1">
                  <span className="text-primary font-medium ">
                    {formatVND(record.moneyServicePack)}
                  </span>
                </div>
              )}
            ></Column>
          </>
        )}
        <Column
          align="right"
          width={200}
          title="Phương thức thanh toán"
          render={(text, record: RecordSale) => (
            <div className="flex flex-col gap-1">
              {record?.paymentType ? (
                <span className="font-medium text-primary">
                  {RecordSalePaymentTypeTrans[record.paymentType]?.label}{" "}
                  {record.paymentType === RecordSalePaymentType.Bank && (
                    <Popover
                      content={
                        <div className="flex flex-col gap-2">
                          <div className="flex items-center">
                            <span className="font-medium w-[130px]">
                              Số tài khoản:
                            </span>
                            <span className="font-medium text-primary">
                              {record.bankAccountNumber}
                            </span>
                          </div>
                          <div className="flex items-center">
                            <span className="font-medium w-[130px]">
                              Tên chủ tài khoản:
                            </span>
                            <span className="font-medium text-primary">
                              {record.bankAccountName}
                            </span>
                          </div>
                          <div className="flex items-center">
                            <span className="font-medium w-[130px]">
                              Tên ngân hàng:
                            </span>
                            <span className="font-medium text-primary">
                              {record.bankName}
                            </span>
                          </div>
                        </div>
                      }
                    >
                      <InfoCircleOutlined className="text-primary" />
                    </Popover>
                  )}
                </span>
              ) : (
                <span className="font-semibold text-primary">---</span>
              )}
            </div>
          )}
        ></Column>
        <Column
          align="right"
          fixed={"right"}
          width={180}
          title="Tiền hoa hồng"
          render={(text, record: RecordSale) => {
            const details = JSON.parse(
              record?.details || "[]"
            ) as RecordSaleDetails[];
            return (
              <div className="flex gap-1 items-center justify-end">
                <span className="text-primary font-medium ">
                  {formatVND(record.moneyCommission)} (
                  {record?.commissionPercent}
                  %)
                </span>
                <Popover
                  content={
                    <div className="flex flex-col gap-2">
                      {details?.map((item) => (
                        <div className="flex items-center w-[300px] justify-between">
                          <span className="font-medium whitespace-nowrap">
                            {item.content}
                          </span>
                          <span className="font-medium text-primary whitespace-nowrap">
                            {item.commission}%
                          </span>
                        </div>
                      ))}
                    </div>
                  }
                >
                  <InfoCircleOutlined className="text-primary" />
                </Popover>
              </div>
            );
          }}
        ></Column>

        <Column
          align="right"
          fixed={"right"}
          width={150}
          title="Tiền trả theo gói"
          render={(text, record: RecordSale) => (
            <div className="flex justify-end text-primary gap-1">
              <span className="text-primary font-medium cursor-pointer">
                {formatVND(record.moneyRenew)}
              </span>
              {record.renewPackage && (
                <Popover
                  content={
                    <div className="flex flex-col gap-2">
                      <div className="flex items-center">
                        <span className="font-medium w-[50px]">Gói:</span>
                        <span className="font-medium text-primary">
                          {record.renewPackage?.name}
                        </span>
                      </div>
                      <div className="flex items-center">
                        <span className="font-medium w-[50px]">Mô tả:</span>
                        <span className="font-medium text-primary">
                          {record.renewPackage?.desc}
                        </span>
                      </div>
                      <div className="flex items-center">
                        <span className="font-medium w-[50px]">Giá:</span>
                        <span className="font-medium text-primary">
                          {formatVND(record.renewPackage?.price)}
                        </span>
                      </div>
                      <div className="flex items-center">
                        <span className="font-medium w-fit">
                          Tiền trả theo gói:{" "}
                        </span>
                        <span className="font-medium text-primary pl-2">
                          {" "}
                          {formatVND(record.renewPackage?.price)} / {12} tháng ={" "}
                          {formatVND(record.moneyRenew)}
                        </span>
                      </div>
                    </div>
                  }
                >
                  <InfoCircleOutlined className="text-primary" />
                </Popover>
              )}
            </div>
          )}
        ></Column>

        <Column
          align="center"
          fixed={"right"}
          width={250}
          title="Thanh toán"
          render={(text, record: RecordSale) => (
            <div className="flex flex-col gap-1 items-center">
              {record.paymentStatus === RecordSalePaymentStatus.Pending && (
                <div>
                  <Tag
                    color={
                      RecordSalePaymentStatusTrans[record.paymentStatus].color
                    }
                    className="font-medium w-fit align-center"
                  >
                    {RecordSalePaymentStatusTrans[record.paymentStatus].label}
                  </Tag>
                  <br />
                  Cần thanh toán số tiền:{" "}
                  <span className="text-pink font-medium">
                    {formatVND(record.amount)}{" "}
                  </span>
                  <Popover
                    content={
                      <div className="flex flex-col gap-2">
                        <div className="flex items-center gap-2 justify-between">
                          <span className="font-medium w-[130px]">
                            Tổng tiền hoa hồng:
                          </span>
                          <span className="font-medium text-primary">
                            {formatVND(record.moneyCommission)}
                          </span>
                        </div>
                        <div className="flex items-center justify-between">
                          <span className="font-medium w-[130px]">
                            Tiền trả theo gói:
                          </span>
                          <span className="font-medium text-primary">
                            {formatVND(record.moneyRenew)}
                          </span>
                        </div>
                      </div>
                    }
                  >
                    <InfoCircleOutlined className="!text-pink" />
                  </Popover>
                </div>
              )}
              {record.paymentStatus === RecordSalePaymentStatus.Complete && (
                <>
                  <div>
                    <span className="text-primary font-medium">
                      {formatVND(record.amount)}
                    </span>{" "}
                    <Popover
                      content={
                        <div className="flex flex-col gap-2">
                          <div className="flex items-cente justify-betweenr">
                            <span className="font-medium w-[130px]">
                              Tiền hoa hồng:
                            </span>
                            <span className="font-medium text-primary">
                              {formatVND(record.moneyCommission)}
                            </span>
                          </div>
                          <div className="flex items-center justify-between">
                            <span className="font-medium w-[130px]">
                              Tiền trả theo gói:
                            </span>
                            <span className="font-medium text-primary">
                              {formatVND(record.moneyRenew)}
                            </span>
                          </div>
                        </div>
                      }
                    >
                      <InfoCircleOutlined className="!text-primary" />
                    </Popover>
                  </div>
                  <Tag
                    color={
                      RecordSalePaymentStatusTrans[record.paymentStatus].color
                    }
                    className="font-medium w-fit align-center"
                  >
                    {RecordSalePaymentStatusTrans[record.paymentStatus].label}
                  </Tag>

                  <div>Lúc {formattedFullDate(record.updatedAt)}</div>
                </>
              )}
            </div>
          )}
        />
        {/* <Column
          align="right"
          width={200}
          title="Số tiền cần thanh toán"
          render={(text, record: RecordSale) => (
            <span className="font-medium">{formatVND(record.amount)}</span>
          )}
        />

        <Column
          align="center"
          width={200}
          title="Trạng thái thanh toán"
          render={(text, record: RecordSale) => (
            <div className="flex flex-col gap-1 items-center">
              <Tag
                color={RecordSalePaymentStatusTrans[record.paymentStatus].color}
                className="font-medium w-fit align-center"
              >
                {RecordSalePaymentStatusTrans[record.paymentStatus].label}
              </Tag>
              {record.paymentStatus === RecordSalePaymentStatus.Complete && (
                <span>lúc {unixToFullDate(record.updatedAt)}</span>
              )}
            </div>
          )}
        /> */}

        {/* <Column
          align="center"
          width={200}
          title="Phương thức thanh toán"
          render={(text, record: RecordSale) => (
            <>
              <Tag className="font-medium">
                {RecordSalePaymentTypeTrans[record.paymentType]?.label}
              </Tag>
              {record.paymentType === RecordSalePaymentType.Bank && (
                <Popover
                  content={
                    <div className="flex flex-col gap-2">
                      <div className="flex items-center">
                        <span className="font-medium w-[130px]">
                          Số giao dịch:
                        </span>
                        <span className="font-medium text-primary">
                          {record.transNo}
                        </span>
                      </div>
                      <div className="flex items-center">
                        <span className="font-medium w-[130px]">
                          Số tài khoản:
                        </span>
                        <span className="font-medium text-primary">
                          {record.bankAccountNumber}
                        </span>
                      </div>
                      <div className="flex items-center">
                        <span className="font-medium w-[130px]">
                          Tên chủ tài khoản:
                        </span>
                        <span className="font-medium text-primary">
                          {record.bankAccountName}
                        </span>
                      </div>
                      <div className="flex items-center">
                        <span className="font-medium w-[130px]">
                          Tên ngân hàng:
                        </span>
                        <span className="font-medium text-primary">
                          {record.bankName}
                        </span>
                      </div>
                    </div>
                  }
                >
                  <InfoCircleOutlined className="text-primary" />
                </Popover>
              )}
            </>
          )}
        /> */}

        {isShowAction && (
          <Column
            width={100}
            align="center"
            title=""
            fixed={"right"}
            key="action"
            render={(text, record: RecordSale) => (
              <Space>
                {record?.paymentStatus == RecordSalePaymentStatus.Pending && (
                  <>
                    <Button
                      type="primary"
                      onClick={() =>
                        paymentConfirmModalRef.current?.handleOpen(record)
                      }
                    >
                      Thanh toán
                    </Button>
                  </>
                )}
              </Space>
            )}
          />
        )}
      </Table>
      <Pagination
        currentPage={queryRecordSale.page}
        total={totalRecordSales}
        pageSize={queryRecordSale.limit}
        onChange={({ limit, page }) => {
          fetchRecordSale({ limit, page });
        }}
      />

      <PaymentConfirmModal
        ref={paymentConfirmModalRef}
        onClose={function (): void {
          throw new Error("Function not implemented.");
        }}
        onSubmitOk={fetchRecordSale}
      />
      <MerchantModal
        renewPackages={renewPackages}
        isModalVisible={isModalMerchantVisible}
        onClose={() => setIsModalMerchantVisible(false)}
        onSubmitOk={() => {
          fetchRecordSale();
          // onStatusChanged?.();
        }}
        status={modalMerchantStatus}
        merchant={selectedMerchant}
      />
    </section>
  );
};
