import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Image,
  Input,
  Popconfirm,
  Popover,
  Space,
  Spin,
  Table,
  Typography,
  message,
} from "antd";
import { Pagination } from "components/Pagination";
import useCustomerReview from "hooks/useCustomerReview";
import { useEffect, useRef } from "react";
import { CustomerReview } from "types/customerReview";
import { getTitle } from "utils";
import { CustomerReviewModal } from "./components/CustomerReviewModal";

const { Column } = Table;

export const CustomerReviewPage = ({ title = "" }) => {
  const {
    fetchCustomerReview,
    customerReviews,
    isFetchCustomerReviewLoading,
    totalCustomerReview,
    queryCustomerReview,
    filterCustomerReview,
    deleteCustomerReview,
  } = useCustomerReview({
    initialQuery: {
      page: 1,
      limit: 20,
    },
  });
  const modalRef = useRef<CustomerReviewModal>(null);

  useEffect(() => {
    document.title = getTitle(title);
    fetchCustomerReview();
  }, []);
  return (
    <section className="box">
      <div className="filter-container">
        <Space>
          <div className="filter-item">
            <label htmlFor="">Tìm kiếm</label>
            <Input
              size="middle"
              onChange={(ev) => {
                filterCustomerReview({
                  search: ev.currentTarget.value.trim(),
                  page: 1,
                });
              }}
              placeholder="Tìm tên người review"
            />
          </div>

          <div className="filter-item btn">
            <Button
              onClick={() => fetchCustomerReview()}
              type="primary"
              icon={<SearchOutlined />}
            >
              Tìm kiếm
            </Button>
          </div>

          <div className="filter-item btn">
            <Button
              onClick={() => {
                modalRef.current?.handleCreate();
              }}
              type="primary"
              icon={<PlusOutlined />}
            >
              Thêm mới
            </Button>
          </div>
        </Space>
      </div>

      <Spin spinning={isFetchCustomerReviewLoading}>
        <Table pagination={false} rowKey="id" dataSource={customerReviews}>
          <Column
            width={80}
            align="center"
            title="Thứ tự"
            dataIndex="no"
            key="no"
            render={(text, record: CustomerReview) => (
              <span className="text-secondary font-medium cursor-pointer hover:underline">
                {text}
              </span>
            )}
          />
          <Column
            title="Tên khách hàng"
            dataIndex="name"
            key="name"
            render={(text, record: CustomerReview) => (
              <div className="flex items-center gap-3">
                <Image
                  src={record.avatar || "/logo.png"}
                  preview={false}
                  className="rounded-full shadow-md !w-[40px] !h-[40px] object-cover"
                />
                <span
                  onClick={() => {
                    modalRef.current?.handleUpdate(record);
                  }}
                  className="text-secondary font-medium cursor-pointer hover:underline"
                >
                  {text}
                </span>
              </div>
            )}
          />

          <Column title="Địa chỉ" dataIndex="address" key="address" />
          <Column
            title="Nội dung review"
            dataIndex="content"
            width={600}
            key="content"
            render={(text, record: CustomerReview) => {
              return (
                <Popover
                  trigger={"hover"}
                  title="Nội dung câu hỏi"
                  content={
                    <Typography.Paragraph style={{ width: 700 }}>
                      {text}
                    </Typography.Paragraph>
                  }
                >
                  <Typography.Paragraph
                    ellipsis={{ expandable: false, rows: 1 }}
                    style={{ width: 500, marginBottom: 0 }}
                  >
                    {text}
                  </Typography.Paragraph>
                </Popover>
              );
            }}
          />

          <Column
            width={150}
            title="Hành động"
            key="action"
            render={(text, record: CustomerReview) => (
              <Space>
                <Button
                  type="primary"
                  onClick={() => {
                    modalRef.current?.handleUpdate(record);
                  }}
                >
                  Cập nhật
                </Button>
                <Popconfirm
                  title="Bạn có chắc chắn muốn thực hiện thao tác?"
                  onConfirm={async () => {
                    await deleteCustomerReview(record.id);
                    message.success("Xóa thành công!");
                  }}
                >
                  <Button type="primary" danger>
                    Xóa
                  </Button>
                </Popconfirm>
              </Space>
            )}
          />
        </Table>

        <Pagination
          currentPage={queryCustomerReview.page}
          total={totalCustomerReview}
          pageSize={queryCustomerReview.limit}
          onChange={({ limit, page }) => {
            // filterData({ limit, page });
          }}
        />
      </Spin>

      <CustomerReviewModal
        // CustomerReviewList={CustomerReviews}
        onSubmitOk={fetchCustomerReview}
        onClose={() => {}}
        ref={modalRef}
      />
    </section>
  );
};
