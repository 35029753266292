import { SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Descriptions,
  Image,
  Input,
  Modal,
  Popconfirm,
  Popover,
  Select,
  Space,
  Spin,
  Table,
  Tag,
  Typography,
  message,
  Badge,
  Tabs,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { Pagination } from "components/Pagination";
import {
  OneSignalDataType,
  useOneSignalContext,
} from "context/OneSignalContext";
import useDictionary from "hooks/useDictionary";
import useLead from "hooks/useLead";
import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { DictionaryType } from "types/dictionary";
import { Lead, LeadStatus, LeadStatusTrans } from "types/lead";
import { getTitle } from "utils";
import { unixToFullDate } from "utils/dateFormat";
import { leadApi } from "api/lead.api";
import { conversationStore } from "store/conversationStore";
import { observer } from "mobx-react";

const { Column } = Table;
interface ILeadStatus {
  status: LeadStatus;
  total: number;
}
export const LeadPage = observer(({ title = "", description = "" }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [iLeadStatus, setILeadStatus] = useState<ILeadStatus[]>([]);

  const {
    fetchLead,
    isFetchLeadLoading,
    leads,
    queryLead,
    totalLead,
    deleteLead,
    resolveLead,
    filterLead,
  } = useLead({
    initialQuery: {
      status: "NEW",
      page: 1,
      limit: 20,
    },
  });
  const handleFetchMerchantSummary = useCallback(async () => {
    const res = await leadApi.summary();
    setILeadStatus(res.data);
  }, []);
  useEffect(() => {
    handleFetchMerchantSummary();
  }, [leads]);
  const { addListCallback, removeListCallback } = useOneSignalContext();
  const { fetchDictionary, dictionaries } = useDictionary({
    initialQuery: {
      page: 1,
      limit: 20,
      type: DictionaryType.LeadTopic,
    },
  });
  const [privateNote, setPrivateNote] = useState<string>();
  const [selectedLead, setSelectedLead] = useState<Lead>();
  const [isLoading, setIsLoading] = useState(false);
  const [queryParameters, setQueryParameters] = useSearchParams();
  // const modalRef = useRef<LeadModal>(null);

  useEffect(() => {
    const ev = addListCallback?.(
      OneSignalDataType.NEW_MERCHANT,
      (data: any) => {
        fetchLead();
      }
    );
    return () => {
      removeListCallback?.(ev);
    };
  }, []);
  useEffect(() => {
    document.title = getTitle(title);
    conversationStore.countNewConversation();
    const queryLeadId = queryParameters.get("id");
    if (queryLeadId) {
      queryLead.id = +queryLeadId;
      queryParameters.delete("id");
      setQueryParameters(queryParameters);
    }
    fetchLead();
    queryLead.id = undefined;
    fetchDictionary();
  }, []);
  const handleSubmitPrivateNote = async () => {
    setIsLoading(true);
    if (!privateNote) {
      return message.error("Vui lòng nhập nội dung xử lý");
    }
    try {
      await resolveLead(selectedLead!.id, { privateNote });
      message.success("Xử lý thành công!");
      await conversationStore.countNewConversation();
      onCloseModal();
      fetchLead();
    } finally {
      setIsLoading(false);
    }
  };
  const onCloseModal = () => {
    setIsModalOpen(false);
    setSelectedLead(undefined);
    setPrivateNote("");
  };
  return (
    <section className="box">
      <div className="flex flex-col gap-1 mb-5">
        <h1 className="text-xl m-0 ">{title}</h1>
        <span className="font-medium">{description}</span>
      </div>
      <Tabs
        onChange={(value) => {
          queryLead.status = value;
          fetchLead();
        }}
      >
        {Object.values(LeadStatusTrans).map((status) => (
          <Tabs.TabPane
            key={status.value}
            tabKey={status.value}
            tab={
              <span>
                {status.label}
                <Badge
                  count={
                    iLeadStatus?.find((stat) => stat.status === status.value)
                      ?.total || 0
                  }
                  showZero
                  size={"small"}
                  color={status.color}
                  style={{ fontSize: 10, marginLeft: 5, marginBottom: 10 }}
                ></Badge>
              </span>
            }
          >
            <div className="filter-container">
              <Space wrap>
                <div className="filter-item">
                  <label htmlFor="">Tìm kiếm</label>
                  {/* <br /> */}
                  <Input
                    size="middle"
                    onChange={(ev) => {
                      filterLead({ search: ev.currentTarget.value, page: 1 });
                    }}
                    placeholder="Tìm tên người gửi"
                  />
                </div>
                <div className="filter-item">
                  <label htmlFor="">Chủ đề</label>
                  <div>
                    <Select
                      onChange={(value) => {
                        filterLead({ leadTopicId: value });
                      }}
                      // mode="multiple"
                      className="!h-auto"
                      options={dictionaries}
                      fieldNames={{
                        value: "id",
                        label: "name",
                      }}
                      allowClear
                      style={{
                        minWidth: 200,
                      }}
                      placeholder={"Loại chủ đề"}
                    ></Select>
                  </div>
                </div>

                <div className="filter-item btn">
                  <Button
                    onClick={() => fetchLead()}
                    type="primary"
                    icon={<SearchOutlined />}
                  >
                    Tìm kiếm
                  </Button>
                </div>

                {/* <div className="filter-item btn">
              <Button
                onClick={() => {
                  modalRef.current?.handleCreate();
                }}
                type="primary"
                icon={<PlusOutlined />}
              >
                Thêm mới
              </Button>
            </div> */}
              </Space>
            </div>

            <Spin spinning={isFetchLeadLoading}>
              <Table
                pagination={false}
                rowKey="id"
                dataSource={leads}
                scroll={{
                  x: "max-content",
                }}
              >
                <Column
                  title="Tên liên hệ"
                  dataIndex="fullName"
                  width={250}
                  key="fullName"
                  render={(text, record: Lead) => {
                    return (
                      <div className="flex items-center gap-3">
                        <span className="text-secondary font-medium hover:underline">
                          {text}
                        </span>
                      </div>
                    );
                  }}
                />
                <Column
                  title="Email"
                  dataIndex="email"
                  width={200}
                  key="email"
                  render={(text, record: Lead) => {
                    return <span>{text}</span>;
                  }}
                />
                <Column
                  title="Số điện thoại"
                  dataIndex="phone"
                  width={150}
                  key="phone"
                  render={(text, record: Lead) => {
                    return <span>{text}</span>;
                  }}
                />
                {/* <Column
              title="Thông tin người gửi"
              dataIndex="name"
              width={400}
              key="name"
              render={(text, record: Lead) => {
                return (
                  <Descriptions column={1} labelStyle={{ fontWeight: 600 }}>
                    <DescriptionsItem label="Họ tên" style={{ padding: 2 }}>
                      {record?.fullName || "Không có thông tin"}
                    </DescriptionsItem>
                    <DescriptionsItem label="Email" style={{ padding: 2 }}>
                      {record?.email || "Không có thông tin"}
                    </DescriptionsItem>
                    <DescriptionsItem
                      label="Số điện thoại"
                      style={{ padding: 2 }}
                    >
                      {record?.phone || "Không có thông tin"}
                    </DescriptionsItem>
                  </Descriptions>
                );
              }}
            /> */}

                <Column
                  title="Chủ đề"
                  dataIndex="title"
                  width={200}
                  key="title"
                  render={(text, record: Lead) => {
                    return <span>{record?.leadTopic?.name}</span>;
                  }}
                />
                <Column
                  title="Nội dung"
                  dataIndex="content"
                  width={300}
                  key="content"
                  render={(text, record: Lead) => {
                    return (
                      <Popover
                        trigger={"hover"}
                        title="Nội dung liên lạc"
                        content={
                          <Typography.Paragraph style={{ width: 300 }}>
                            {text}
                          </Typography.Paragraph>
                        }
                      >
                        <Typography.Paragraph
                          ellipsis={{ expandable: false, rows: 1 }}
                          style={{ width: 300, marginBottom: 0 }}
                        >
                          {text}
                        </Typography.Paragraph>
                      </Popover>
                    );
                  }}
                />
                <Column
                  title="Thông tin xử lý"
                  dataIndex="privateNote"
                  width={300}
                  key="privateNote"
                  render={(text, record: Lead) => {
                    return (
                      <Popover
                        trigger={"hover"}
                        title="Nội dung liên lạc"
                        content={
                          <Typography.Paragraph style={{ width: 700 }}>
                            {text}
                          </Typography.Paragraph>
                        }
                      >
                        <Typography.Paragraph
                          ellipsis={{ expandable: false, rows: 1 }}
                          style={{ width: 300, marginBottom: 0 }}
                        >
                          {text}
                        </Typography.Paragraph>
                      </Popover>
                    );
                  }}
                />
                {/* <Column
              title="Người xử lý"
              width={250}
              render={(text, record: Lead) => {
                return (
                  <Descriptions column={1} labelStyle={{ fontWeight: 600 }}>
                    <DescriptionsItem label="Họ tên">
                      {record?.staff?.name || "Không có thông tin"}
                    </DescriptionsItem>
                    <DescriptionsItem label="Email">
                      {record?.staff?.email || "Không có thông tin"}
                    </DescriptionsItem>
                  </Descriptions>
                );
              }}
            /> */}
                <Column
                  align="center"
                  title="Trạng thái"
                  dataIndex="status"
                  width={150}
                  key="status"
                  render={(text, { status }: Lead) => {
                    return (
                      <Tag color={LeadStatusTrans[status].color}>
                        {LeadStatusTrans[status].label}
                      </Tag>
                    );
                  }}
                />
                {/* <Column
              title="Thời gian"
              width={300}
              render={(text, record: Lead) => {
                return (
                  <Descriptions
                    column={1}
                    labelStyle={{ fontWeight: 600 }}
                    style={{ padding: 0 }}
                  >
                    <DescriptionsItem label="Tạo" style={{ padding: 2 }}>
                      {unixToFullDate(record?.createdAt)}
                    </DescriptionsItem>
                    <DescriptionsItem label="Xử lý" style={{ padding: 2 }}>
                      {record?.updatedAt !== record.createdAt
                        ? unixToFullDate(record?.updatedAt)
                        : "Chưa có thông tin"}
                    </DescriptionsItem>
                  </Descriptions>
                );
              }}
            /> */}
                <Column
                  align="center"
                  title="Thời gian tạo"
                  width={150}
                  render={(text, record: Lead) => {
                    return <span>{unixToFullDate(record?.createdAt)}</span>;
                  }}
                />
                <Column
                  align="center"
                  title="Thời gian xử lý"
                  width={150}
                  render={(text, record: Lead) => {
                    return (
                      <span>
                        {record?.updatedAt !== record.createdAt
                          ? unixToFullDate(record?.updatedAt)
                          : "Chưa có thông tin"}
                      </span>
                    );
                  }}
                />

                <Column
                  fixed="right"
                  width={150}
                  title="Hành động"
                  key="action"
                  render={(text, record: Lead) => (
                    <Space>
                      {record?.status !== LeadStatus.Resolved && (
                        <>
                          {/* <Popconfirm
                        title={
                          <>
                            <h1>Xác nhận xử lý liên hệ này?</h1>
                            <TextArea
                              rows={4}
                              className="w-[300px]"
                              placeholder="Nhập vào thông tin xử lý"
                              onChange={(e) => setPrivateNote(e.target.value)}
                            ></TextArea>
                          </>
                        }
                        onConfirm={async () => {
                          await resolveLead(record.id, { privateNote });
                          message.success("Xử lý thành công!");
                          fetchLead();
                        }}
                      > */}
                          <Button
                            type="primary"
                            onClick={() => {
                              setIsModalOpen(true);
                              setSelectedLead(record);
                            }}
                          >
                            Xử lý
                          </Button>
                          {/* </Popconfirm> */}
                          <Popconfirm
                            title="Bạn có chắc chắn muốn thực hiện thao tác?"
                            onConfirm={async () => {
                              await deleteLead(record.id);
                              message.success("Xóa thành công!");
                              await conversationStore.countNewConversation();
                            }}
                          >
                            <Button type="primary" danger>
                              Xóa
                            </Button>
                          </Popconfirm>
                        </>
                      )}
                    </Space>
                  )}
                />
              </Table>

              <Pagination
                currentPage={queryLead.page}
                total={totalLead}
                pageSize={queryLead.limit}
                onChange={({ limit, page }) => {
                  // filterData({ limit, page });
                }}
              />
            </Spin>
            <Modal
              title="Xác nhận xử lý liên hệ này?"
              open={isModalOpen}
              onOk={handleSubmitPrivateNote}
              onCancel={onCloseModal}
              confirmLoading={isLoading}
            >
              <>
                <TextArea
                  rows={4}
                  required
                  // className="w-[300px]"
                  placeholder="Nhập vào thông tin xử lý"
                  value={privateNote}
                  onChange={(e) => {
                    setPrivateNote(e.target.value);
                  }}
                ></TextArea>
              </>
            </Modal>
          </Tabs.TabPane>
        ))}
      </Tabs>
      {/* <LeadModal onSubmitOk={fetchLead} onClose={() => {}} ref={modalRef} /> */}
    </section>
  );
});
