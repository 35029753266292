import { Button, Input, Select, Space, Spin, Table } from "antd";

import { Pagination } from "components/Pagination";
import { observer } from "mobx-react";
import { useEffect, useRef, useState } from "react";
import { ContentDefine, ContentDefineTypeTrans } from "types/contentDefine";
import { ModalStatus } from "types/modal";
import { QueryParam } from "types/query";
import { getTitle } from "utils";
import { DictionaryModal } from "./components/DictionaryModal";
import { useHandlerDictionary } from "./handler/useHandlerDictionary";
import { Dictionary, DictionaryType } from "types/dictionary";
import useDictionary from "hooks/useDictionary";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { Popconfirm } from "antd/lib";
import settings from "settings";
// import { StaffModal } from "./components/StaffModal";

const { Column } = Table;
const { Option } = Select;

interface BannerQuery extends QueryParam {
  areaId: null | null;
}

export const DictionaryPage = observer(({ title = "" }) => {
  const {
    fetchDictionary,
    isFetchDictionaryLoading,
    dictionaries,
    queryDictionary,
    totalDictionary,
    filterDictionary,
    deleteDictionary,
  } = useDictionary({
    initialQuery: {
      page: 1,
      limit: 20,
      type: DictionaryType.SearchContent,
    },
  });

  const modalRef = useRef<DictionaryModal>(null);

  const [selectedContentDefine, setSelectedContentDefine] = useState<
    Partial<ContentDefine>
  >({});
  const [modalStatus, setModalStatus] = useState<ModalStatus>("create");

  useEffect(() => {
    document.title = getTitle(title);
    fetchDictionary();
  }, []);
  return (
    <section className="box">
      <div className="filter-container">
        <Space>
          {/* <div className="filter-item">
            <label htmlFor="">Tìm kiếm</label>
            <Input
              size="middle"
              onChange={(ev) => {
                filterDictionary({ search: ev.currentTarget.value, page: 1 });
              }}
              placeholder="Tìm tên đánh giá mẫu"
            />
          </div>

          <div className="filter-item btn">
            <Button
              onClick={() => fetchDictionary()}
              type="primary"
              icon={<SearchOutlined />}
            >
              Tìm kiếm
            </Button>
          </div> */}

          {settings.isDev && (
            <div className="filter-item btn">
              <Button
                onClick={() => {
                  modalRef.current?.handleCreate();
                }}
                type="primary"
                icon={<PlusOutlined />}
              >
                Thêm mới
              </Button>
            </div>
          )}
        </Space>
      </div>

      <Spin spinning={isFetchDictionaryLoading}>
        <Table pagination={false} rowKey="id" dataSource={dictionaries}>
          <Column
            title="Nội dung"
            dataIndex="name"
            key="name"
            render={(text, record: Dictionary) => {
              return (
                <div
                  dangerouslySetInnerHTML={{ __html: record?.name }}
                  onClick={() => {
                    modalRef.current?.handleUpdate(record);
                  }}
                  className="ql-editor text-secondary cursor-pointer hover:underline font-medium"
                ></div>
              );
            }}
          />

          <Column
            width={150}
            title="Hành động"
            key="action"
            render={(text, record: Dictionary) => (
              <Space>
                <Button
                  type="primary"
                  onClick={() => {
                    modalRef.current?.handleUpdate(record);
                  }}
                >
                  Cập nhật
                </Button>
                {settings.isDev && (
                  <Popconfirm
                    title="Bạn có chắc chắn muốn thực hiện thao tác?"
                    onConfirm={async () => {
                      await deleteDictionary(record.id);
                    }}
                  >
                    <Button type="primary" danger>
                      Xóa
                    </Button>
                  </Popconfirm>
                )}
              </Space>
            )}
          />
        </Table>

        <Pagination
          currentPage={queryDictionary.page}
          total={totalDictionary}
          pageSize={queryDictionary.limit}
          onChange={({ limit, page }) => {
            // filterData({ limit, page });
          }}
        />
      </Spin>

      <DictionaryModal
        dictionaryList={dictionaries}
        onSubmitOk={fetchDictionary}
        onClose={() => {}}
        ref={modalRef}
      />
    </section>
  );
});
